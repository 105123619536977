import React from "react";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUserSecret,
  faFileContract,
  faHandshake,
  faScaleBalanced,
  faGears,
  faMagnifyingGlassDollar,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

export const Footer = (props) => {
  const { t } = useTranslation();
  return (
    <div id="footer">
      <div className="container row">
        {/* <div className="col-md-4 col-sx-12">
            <FontAwesomeIcon style={{ marginRight: "5px" }} icon={faEnvelope} />
            <a href="mailto:cs@ai-prop.com">cs@ai-prop.com</a>
          </div> */}

        <p className="col-lg-4 col-md-12 col-sx-12">© {t(`copy_right`)}</p>
        <div className="col-lg-8 col-md-12 col-sx-12 text-right">
          <Link style={{ marginRight: "10px" }} to={"/law"}>
            <FontAwesomeIcon
              style={{ marginRight: "5px" }}
              icon={faGears}
            />
            {t(`law`)}
          </Link>

          <Link style={{ marginRight: "10px" }} to={"/settlement"}>
            <FontAwesomeIcon
              style={{ marginRight: "5px" }}
              icon={faMagnifyingGlassDollar}
            />
            {t(`settlement`)}
          </Link>
          <Link
            to="/privacy-policy"
            style={{ marginRight: "10px" }}
            // data-toggle="modal"
            // data-target="#privacyPolicyModal"
          >
            <FontAwesomeIcon
              style={{ marginRight: "5px" }}
              icon={faUserSecret}
            />
            {t(`privacy_policy`)}
          </Link>

          <Link to={"/term-of-service"}>
            <FontAwesomeIcon
              style={{ marginRight: "5px" }}
              icon={faFileContract}
            />
            {t(`terms_of_service`)}
          </Link>
        </div>
      </div>
    </div>
  );
};
