import React, { useState, useEffect } from "react";
import { Navigation } from "./components/navigation";
import { Header } from "./components/header";
import { About } from "./components/about";
import { Services } from "./components/services";
import { Reviews } from "./components/reviews";
import { Contact } from "./components/contact";
import JsonData from "./data/data.json";
import { StartNow } from "./components/startNow";
import SmoothScroll from "smooth-scroll";
import { HowItWork } from "./components/howItWork";
import { ToastContainer } from "react-toastify";
import { Sample } from "./components/samples";
import { TryPhoto } from "./components/tryPhoto";
import "./App.css";
import "swiper/swiper-bundle.css";
import "react-toastify/dist/ReactToastify.css";
import { FAQ } from "./components/faq";
import { WhyUs } from "./components/whyUs";
import { Pricing } from "./components/pricing";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Footer } from "./components/footer";
import { PrivacyPolicy } from "./components/privacyPolicy";
import { TermOfService } from "./components/termOfService";
import { ComingSoon } from "./components/comingSoon";
import { Law } from "./components/law";
import { Settlement } from "./components/settlement";
import { COMING_SOON_MODE } from "./constants";
export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
  offset: 100,
});

const App = () => {
  const [landingPageData, setLandingPageData] = useState({});

  useEffect(() => {
    setLandingPageData(JsonData);
  }, []);
  const HomeApp = () => {
    return (
      <>
        <Header data={landingPageData.Header} />
        <StartNow />
        <WhyUs />
        <TryPhoto />
        <Services data={landingPageData.Services} />
        <HowItWork />
        <Pricing />
        <Sample />
        <About data={landingPageData.About} />
        <FAQ />
        <Reviews />
        <Contact data={landingPageData.Contact} />
      </>
    );
  };
  return (
    <Router>
      {!COMING_SOON_MODE && <Navigation />}
      <Routes>
        <Route
          path="/"
          element={COMING_SOON_MODE ? <ComingSoon /> : <HomeApp />}
        />
        <Route path="/law" element={<Law />} />
        <Route path="/settlement" element={<Settlement />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/term-of-service" element={<TermOfService />} />
      </Routes>
      {!COMING_SOON_MODE && <Footer />}

      <ToastContainer />
      <div
        className="modal fade"
        id="youtubeModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="youtubeModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-youtube" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="embed-responsive embed-responsive-16by9">
                <iframe
                  width="560"
                  height="315"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen=""
                  src="https://www.youtube.com/embed/UShZKUX1i-Q?si=NEJY39_h7EvkU3tO&amp;controls=1&autoplay=0&rel=0"
                  className="lazyloaded"

                  // src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Router>
  );
};

export default App;
