import React, {useEffect} from "react";
import { useTranslation } from "react-i18next";
const data = [
  {
    title: "law_title1",
    description: "law_description1",
  },
  {
    title: "law_title2",
    description: "law_description2",
  },
  {
    title: "law_title3",
    description: "law_description3",
  },
  {
    title: "law_title4",
    description: "law_description4",
  },
  {
    title: "law_title5",
    description: "law_description5",
  },
  {
    title: "law_title6",
    description: "law_description6",
  },
  {
    title: "law_title7",
    description: "law_description7",
  },
  {
    title: "law_title8",
    description: "law_description8",
  },
  {
    title: "law_title9",
    description: "law_description9",
  },
  {
    title: "law_title10",
    description: "law_description10",
  },
  {
    title: "law_title11",
    description: "law_description11",
  },
];
export const Law = (props) => {
  const { t } = useTranslation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div id="lawModal">
      <div className="modal-law">
        <div>
          <div className="modal-body">
            <h2>{t("law_header")}</h2>
            <table>
              <tbody>
                {data.map((item, index) => (
                  <tr key={index}>
                    <td>{t(item.title)}</td>
                    <td>
                      {t(item.description)
                        .split("\n")
                        .map((line, ind) => (
                          <React.Fragment key={ind}>
                            {line}
                            <br />
                          </React.Fragment>
                        ))}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};
