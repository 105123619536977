import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
const data = [
  {
    title: "settlement_title1",
    description: "settlement_description1",
  },
  {
    title: "settlement_title2",
    description: "settlement_description2",
  },
  {
    title: "settlement_title3",
    description: "settlement_description3",
  },
  {
    title: "settlement_title4",
    description: "settlement_description4",
  },
  {
    title: "settlement_title5",
    description: "settlement_description5",
  },
  {
    title: "settlement_title6",
    description: "settlement_description6",
  },
  {
    title: "settlement_title7",
    description: "settlement_description7",
  },
  {
    title: "settlement_title8",
    description: "settlement_description8",
  },
  {
    title: "settlement_title9",
    description: "settlement_description9",
  },
  {
    title: "settlement_title10",
    description: "settlement_description10",
  },
  {
    title: "settlement_title11",
    description: "settlement_description11",
  },
  {
    title: "settlement_title12",
    description: "settlement_description12",
  },
  {
    title: "settlement_title13",
    description: "settlement_description13",
  },
  {
    title: "settlement_title14",
    description: "settlement_description14",
  },
  {
    title: "settlement_title15",
    description: "settlement_description15",
  },
];
export const Settlement = (props) => {
  const { t } = useTranslation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div id="settlementModal">
      <div className="modal-law">
        <div>
          <div className="modal-body">
            <h2>{t("settlement_header")}</h2>
            <table>
              <tbody>
                {data.map((item, index) => (
                  <tr key={index}>
                    <td>{t(item.title)}</td>
                    <td>
                      {t(item.description)
                        .split("\n")
                        .map((line, ind) => (
                          <React.Fragment key={ind}>
                            {line}
                            <br />
                          </React.Fragment>
                        ))}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div
              style={{ marginLeft: "20px" }}
              className="text-danger text-left w-100"
            >
              <span>{t("settlement_note")}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
