import { useState } from "react";

import ReCAPTCHA from "react-google-recaptcha";
import React from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { GOOGLE_CAPTCHA_KEY, BACKEND_URL } from "../constants";
import axios from "axios";
const initialState = {
  name: "",
  email: "",
  message: "",
};
const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

// Function to validate email
function validateEmail(email) {
  return emailRegex.test(email);
}
export const Contact = (props) => {
  const [{ name, email, message }, setState] = useState(initialState);
  const [verify, setVerify] = useState(false);
  const { t } = useTranslation();
  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  };
  const clearState = () => setState({ ...initialState });
  const onCaptchaChange = (value) => {
    console.log("Captcha value:", value);
    if (value) {
      setVerify(value);
    }
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    console.log(name, email, message);
    //Validate form
    if (!name || !email || !message) {
      toast.error(t(`contact_field_required`));
      return;
    }
    if (!validateEmail(email)) {
      toast.error(t(`contact_email_invalid`));
      return;
    }
    axios
      .post(BACKEND_URL + "/api/v1/public/contact", {
        name,
        email,
        message,
        language: localStorage.getItem("i18nextLng"),
      })
      .then((response) => {
        if (response.status === 200) {
          toast.success(t("contact_success"));
          clearState();
          setVerify(false);
        } else {
          toast.error(t("contact_error"));
        }
        // assign image to the target element
      })
      .catch((error) => {
        toast.error(t("contact_error"));
      });
  };
  return (
    <section id="contact">
      <div className="contInner">
        <h2 className="hdg_ttl02">
          <div>
            <span>{t(`contact_title`)}</span>
          </div>
        </h2>
        <form name="sentMessage" validate="true" onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-sm-3">
              <label>{t(`contact_name`)}</label>
            </div>
            <div className="col-sm-9">
              <div className="form-group">
                <input
                  type="text"
                  id="name"
                  name="name"
                  className="form-control"
                  placeholder={t(`contact_name`)}
                  required
                  onChange={handleChange}
                />
                <p className="help-block text-danger"></p>
              </div>
            </div>
          </div>
          <div className="form-group row">
            <div className="col-sm-3">
              <label>{t(`contact_email`)}</label>
            </div>
            <div className="col-sm-9">
              <div className="form-group">
                <input
                  type="email"
                  id="email"
                  name="email"
                  className="form-control"
                  placeholder={t(`contact_email`)}
                  required
                  onChange={handleChange}
                />
                <p className="help-block text-danger"></p>
              </div>
            </div>
          </div>
          <div className="form-group row">
            <div className="col-sm-3">
              <label>{t(`contact_message`)}</label>
            </div>
            <div className="col-sm-9">
              <textarea
                name="message"
                id="message"
                className="form-control"
                rows="4"
                placeholder={t(`contact_message`)}
                required
                onChange={handleChange}
              ></textarea>
              <p className="help-block text-danger"></p>
            </div>
          </div>
          <div className="form-group row">
            <div className="col-sm-3"></div>
            <div className="col-sm-9">
              <ReCAPTCHA
                sitekey={GOOGLE_CAPTCHA_KEY} // Replace with your reCAPTCHA site key
                onChange={onCaptchaChange}
              />
            </div>
          </div>
          <div id="success"></div>

          <button
            disabled={!verify}
            type="submit"
            className="btn btn-custom btn-lg"
          >
            {t(`contact_send_btn`)}
          </button>
        </form>
      </div>
    </section>
  );
};
