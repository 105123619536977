export const CLIENT_PORTAL = "https://client.ai-prop.com";
//export const CLIENT_PORTAL = "http://ai-prop-website.s3-website-ap-southeast-1.amazonaws.com/"
export const BACKEND_URL = "https://api.ai-prop.com";
//export const BACKEND_URL = "http://34.143.228.78"
export const PHOTO_FILE_TYPE = ["image/jpeg", "image/png", "image/jpg"];
export const PHOTO_MAX_SIZE = 5000000; //5mb
export const PHOTO_MIN_SIZE = 100000; //100kb
export const PHOTO_ENHANCE_SERVICE = 1;
export const PHOTO_MAX_TIME_TRY = 3;
export const GOOGLE_CAPTCHA_KEY = '6LdUwwYqAAAAAKK3cu9etuiFRcj0Ln4nLbg8jFqN';
export const COMING_SOON_MODE = true;